<template>
  <div class="wrapper">
    <img src="/img/servicepiao.png?v=1"
         alt="">
    <div class="box">
      <div class="txt1"
           data-clipboard-text="ixm-itt001"
           @click="copy('.txt1')"></div>
      <div class="txt2"
           data-clipboard-text="4001783123"
           @click="copy('.txt2')"></div>
    </div>

  </div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  components: {},
  props: {},
  data() {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {
    copy(Clacss) {
      var clipboard = new Clipboard(Clacss)
      clipboard.on('success', e => {
        this.$toast.success('复制成功')
        this.show = false
        // 释放内存
        clipboard.destroy()
      })

      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
  },
  created() { },
  mounted() { }
}
</script>
<style scoped>
.wrapper img {
  width: 7.04rem;
  height: 10.96rem;
  margin-top: 0.5rem;
  margin-left: 0.2rem;
  margin-bottom: 0.5rem;
}
.box {
  position: absolute;
  left: 3rem;
  top: 9.5rem;
}
.txt {
  position: absolute;
  top: 5rem;
  left: 3rem;
  width: 2rem;
  height: 1rem;
}
.txt1 {
  width: 2rem;
  height: 1rem;
}
.txt2 {
  width: 2rem;
  height: 1rem;
}
</style>